<template>
  <div v-bind="$attrs" class="flex" aria-label="Breadcrumb">
    <ol role="list" class="flex items-center space-x-2">
      <li v-for="(page, index) in breadcrumbs" :key="page.name">
        <div class="flex items-center">
          <ChevronRightIcon
            v-if="index !== 0"
            class="h-4 w-4 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          <a
            :href="`/` + page.link.cached_url"
            class="text-xs font-medium"
            :class="{
              'ml-2': index !== 0,
              [`hover:text-${hoverColor}`]: hoverColor,
              'hover:text-gray-700': !hoverColor,
              [isCurrent(page)
                ? `text-${textColor} font-semibold`
                : textColor
                  ? `text-${textColor} font-normal`
                  : 'text-gray-500 font-normal']: true,
            }"
            :aria-current="isCurrent(page) ? 'page' : undefined"
          >
            {{ page.name }}
          </a>
        </div>
      </li>
    </ol>
  </div>
</template>

<script setup>
import { ChevronRightIcon } from "@heroicons/vue/20/solid";
const route = useRoute();

const props = defineProps({
  breadcrumbs: Array,
  textColor: {
    type: String,
    default: "black",
  },
  hoverColor: {
    type: String,
    default: "",
  },
});

function isCurrent(breadcrumb) {
  return route.href === `/${breadcrumb.link.cached_url}`;
}

const config = useRuntimeConfig();

useHead({
  script: [
    {
      type: "application/ld+json",
      children: JSON.stringify({
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: props.breadcrumbs.map((page, index) => ({
          "@type": "ListItem",
          position: index + 1,
          name: page.name,
          item: `${config.public.siteUrl}/${page.link.cached_url}`,
        })),
      }),
    },
  ],
});
</script>
